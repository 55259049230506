import { useEffect } from "react";
import {analytics } from "../firebase-config";
import { logEvent } from "firebase/analytics";
import { motion } from 'framer-motion'
import BlogHead from '../Pages/BlogPage/BlogHead'
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'
import BlogPost from '../Pages/BlogPage/BlogPost'
import LogoutButton from '../components/LoginAuth/LogoutButton'

const Blog = () => {
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: window.location.pathname,
      page_title: document.title,
    });
  }, []);
  return (
    <>
      <motion.div initial=
        {{width: 0}} 
        animate={{width: "100%"}} 
        exit={{x: "100%", transition: {duration: 0.1}}}>
        <LogoutButton/>
        <BlogHead/>
        <BlogPost/>
        <ScrollToTop/>
      </motion.div>   
    </>
  )
}

export default Blog
