import images from '../../constants/images';
import {motion} from 'framer-motion';




const BooksThree = () => {
  
  return (
    <motion.div className='body-book'>
    <div className='row-book'>
      <div className='left-book'>
        <img src={images.book3} alt='cookbook'/>
      </div>
      <div className='right-book'>
        <div className='content-book'>
          <h3 className='book-title'>Knowledge is power</h3>
          <img src={images.spoon} alt='feather' className='feather-img-books'/>
            <motion.h1 
              initial={{opacity:0}}
              whileInView={{opacity:3}}
              transition={{delay:1, duration:1.2}}
              className='book-subtitle'>Melanated People Are Superior
            </motion.h1>
              <p className='p__opensans'>In “Master Chief: Melanated People Are Superior,” The Most Honorable Prophet Eligio The Christ reveals the biological properties and super-hueman qualities of the phenomenal chemical compound known as melanin.
              </p>
              <a href='https://books.apple.com/us/book/master-chief-melanated-people-are-superior/id1488979583' target='blank'rel='noreferrer'> <button type="button" className='content__button'>Learn More</button>
              </a>
        </div>
      </div>
    </div>
    </motion.div>
  );
};

export default BooksThree;