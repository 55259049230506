import './BooksTwo.css';
import images from '../../constants/images';
import {motion} from 'framer-motion';


const BooksTwo = () => {

  return (
    <motion.div className='book-cover'>
      <div className='app__bgO '>
      <div className='body-book'>
        <div className='row-book'>
          <div className='left-book'>
          <img src={images.book2} alt='cookbook'/>
          </div>
          <div className='right-book'>
            <div className='content-book'>
              <h3 className='book-title'>death, consciousness, reincarnation</h3>
              <img src={images.spoon} alt='feather' className='feather-img-books'/>
                <motion.h1 
                  initial={{opacity:0}}
                  whileInView={{opacity:3}}
                  transition={{delay:1, duration:1.2}}
                  className='book-subtitle'>Never Before Heard Information
                </motion.h1>
                  <p className='p__opensans'>In “Master Teacher Drops Never Before Heard Information,” The Most Honorable Immortal Grand Master Chief Eligio The Demigod explains life, death, consciousness, reincarnation, and a host of exclusive spiritual truths and awakenings.
                  </p>
                  <a href='https://books.apple.com/us/book/master-chief-drops-never-before-heard-information/id1481361976' target='blank'rel='noreferrer'> <button type="button" className='content__button'>Learn More</button>
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </motion.div>
    )
  }

export default BooksTwo;