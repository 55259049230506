import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase-config';
import { createUserWithEmailAndPassword,
  signInWithEmailAndPassword,  
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const loginUser = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    return signOut(auth);
  }

  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  return (
    <UserContext.Provider value={{ currentUser, createUser, logout, loginUser, resetPassword, signInWithGoogle }}>
      {children}
    </UserContext.Provider>
  );
}


export const UserAuth = () => {
  return useContext(UserContext);
}


