import './Malia.css';
import images from '../../../constants/images';
import {motion} from 'framer-motion';
import ParticleBackground from '../../../components/Particles/ParticleBackground';



const Malia = () => {
  return (
    <div className='malia-container-full'>
      <div className='malia-container-main'>
        <div className='malia-container-row'>
          <div className='malia-card-two'>
            <motion.img
              src={images.maliaimage1} alt='malia' className='malia-img'/>
          </div>
          <div className='malia-card-two'>
            <h3 className='malia-title'>The Queen of ImagineNation</h3>
            <img src={images.spoon} alt='feather' className='feather-img'/>
            <motion.h1 
              initial={{opacity:0}}
              whileInView={{opacity:3}}
              transition={{delay:1, duration:1.2}}
              className='malia-subtitle'>Mother Goddoes Malia
              </motion.h1>
                <p className='p__opensansA'>Mother Goddoes Malia is the Epitome of Divine Feminine Energy, beauty, charm and grace, and alongside her Divine Masculine, The Most Honorable 3 God, she is a True Force of Nature. Queen's love for dance is unmatched by her passion for Sciences and Esoteric Knowledge. When she dances, it's as if she is tapping into a source of Divine Energy. Her movements are fluid, graceful and full of meaning. The essence of Queen's passion for the divine and all that is sacred, brings wombmen from all walks of life together in celebration of Divine Truth. Her love for Science and Esoteric knowledge is just as strong. Queen Malia has spent countless hours studying the mysteries of the Youniverse, and her curiosity has led her to explore the hidden depths of the world around her.
                </p>
                <br/>
                <p className="p__opensansA">With the knowledge from 3 God, she is becoming and being placed in her Divine Role as a true indigenous wombman that knows her role in the black family and knows that the black man "3 God" is First. He is the head of the family and she is the expression. She cultivates and reproduces his mind and is the true essence of what a wombman is supposed to be in the Kingdom of God.
                </p>
                <br/>
                <p className="p__opensansA">Queen Malia has chosen to take the Honorable and narrow path to face all of her generational curses and heal them with Divine Knowledge of Cellf from The Most High 3 God. Through this, she has become a beacon of light for black wombmen in America to see an alternative way of living, which is an indigenous lifestyle in nature where the black family has reunited. She is a powerful force for healing and spiritual guidance. Queen Malia is always there for those who need her, offering comfort, guidance and support when it is needed the most.
                </p>
                <br/>
                <p className="p__opensansA">
                  -Annointed By The Most High 3 God
                </p>
                <div className='malia-container-row'>
                  <div className='malia-card-one'>
                    <img src={images.maliaimage4}  alt="malia" className='malia-img-card' />
                    <div>
                    </div>
                  </div>
                  <div className='malia-card-one'>
                    <img src={images.maliaimage2}  alt="malia" className='malia-img-card' />
                    <div>
                    </div>
                  </div>
                  <div className='malia-card-one'>
                    <img src={images.maliaimage3}  alt="malia" className='malia-img-card' />
                    <div>
                    </div>
                  </div>
                  </div>
                </div>
                <ParticleBackground />
              </div>
            </div>
          </div>
  )
}

export default Malia;