import React, { lazy, Suspense } from 'react';
import './MainVideo.css';

const LazyComment = lazy(() => import('./Comment'));

const MainVideo = () => {
  return (
    <div className="malicious-page-container ">
      <div className="malicious-video-container">
        <div className="video-wrapper">
          <iframe
            title="Embedded Video"
            src="https://www.youtube.com/embed/4Fezivl8IFU?rel=0&amp;showinfo=0"
            allowFullScreen
            className="video-iframe"
          ></iframe>
          <h1 className="comment-heading">Express your thoughts</h1>
        </div>
      </div>
      <Suspense fallback={<div>Loading comments...</div>}>
        <LazyComment />
      </Suspense>
    </div>
  );
};

export default MainVideo;
