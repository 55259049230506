import { useEffect } from "react";
import {analytics } from "../firebase-config";
import { logEvent } from "firebase/analytics";
import {  motion } from 'framer-motion';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import BooksPage from '../Pages/BooksPage/BooksPage';
import Footer from '../components/Footer/Footer';
import BooksOne from '../Pages/BooksPage/BooksOne';
import BooksTwo from '../Pages/BooksPage/BooksTwo';
import BooksThree from '../Pages/BooksPage/BooksThree';
import BooksFour from '../Pages/BooksPage/BooksFour';
import BooksFive from '../Pages/BooksPage/BooksFive';
import BooksSix from '../Pages/BooksPage/BooksSix';
import LogoutButton from '../components/LoginAuth/LogoutButton';


const Books = () => {
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: window.location.pathname,
      page_title: document.title,
    });
  }, []);
  return (
      <motion.div initial=
        {{width: 0}} 
        animate={{width: "100%"}} 
        exit={{x: "100%", transition: {duration: 0.1}}}>
          <LogoutButton/>
      <BooksPage />
      <BooksFive />
      <BooksSix />
      <BooksOne />
      <BooksFour />
      <BooksThree />
      <BooksTwo />
      <Footer />
      <ScrollToTop />
    </motion.div>
  );
};

export default Books