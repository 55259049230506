import React, { lazy, Suspense } from 'react';
import { loadFull } from 'tsparticles';
const Particles = lazy(() => import('react-tsparticles'));

const ParticleBackground = () => {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <div>
      <Suspense fallback={<div>Loading particles...</div>}>
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            // Particle options here
            autoPlay: true,
            background: {
              color: {
                value: '#000',
              },
              image: '',
              position: '',
              repeat: '',
              size: '',
              opacity: 1,
            },
  "backgroundMask": {
    "composite": "destination-out",
    "cover": {
      "color": {
        "value": "#fff"
      },
      "opacity": 1
    },
    "enable": false
  },
  "defaultThemes": {},
  "delay": 0,
  "fullScreen": {
    "enable": true,
    "zIndex": -1
  },

    fps_limit: 120,
    interactivity: {
    detect_on: "canvas",
    events: {
    onclick: { enable: true, mode: "repulse" },
    onhover: {
    enable: true,
      mode: "circle",
      parallax: { enable: false, force: 2, smooth: 10 }
  },
    resize: true
  },
    modes: {
    bubble: { distance: 250, duration: 4, opacity: 0, size: 4, speed: 2 },
    grab: { distance: 400, line_linked: { opacity: 1 } },
    push: { particles_nb: 2 },
    remove: { particles_nb: 1 },
    repulse: { distance: 400, duration: 0.4 }
  }
},
  particles: {
    color: { value: "#ffffff" },
    line_linked: {
    color: "#ffffff",
    distance: 150,
    enable: false,
    opacity: 0.4,
},
  move: {
    size: true,
    attract: { enable: false, rotateX: 600, rotateY: 600 },
    bounce: false,
    direction: "none",
    enable: true,
    out_mode: "out",
    random: true,
    speed: 0.1,
    straight: false
},
  number: { density: { enable: true, value_area: 800 }, value: 90 },
  opacity: {
    anim: { enable: true, opacity_min: 0, speed: 1, sync: false },
    random: true,
    value: 1
},
    shape: {
    character: {
    fill: false,
    font: "Verdana",
    style: "",
    value: "*",
    weight: "100"
  },
  polygon: { nb_sides: 5 },
  stroke: { color: "#000000", width: 0 },
  type: "polygon"
},
    size: {
    anim: { enable: false, size_min: 0.3, speed: 4, sync: false },
    random: true,
    value: 3
}
  },
    polygon: {
    draw: { enable: false, lineColor: "#ffffff", lineWidth: 0.5 },
    move: { radius: 10 },
    scale: 1,
    type: "none",
    url: ""
  },
    etina_detect: true    
}}
  />  
  </Suspense>
  </div>
  );
}

export default ParticleBackground