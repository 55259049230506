import './App.css';
import Navbar from './components/navbar/Navbar';
import AnimatedRoutes from './routes/AnimatedRoutes';


function App() {
  return (
    <>
      <Navbar/>
      <AnimatedRoutes/>  
    </>
  );
}

export default App;


