import React, { useEffect, useState } from 'react';
import './Discretion.css'


const Discretion = () => {
  const [showMessage, setShowMessage] = useState(true);


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 5000);


    return () => clearTimeout(timer);
  }, []);


  return showMessage ? (
    <div className="discretion-message">
      <p>Viewer Discretion Advised: This website contains mature content that may not be suitable for all audiences. Please proceed with caution.</p>
    </div>
  ) : null;
};


export default Discretion;
