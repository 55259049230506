import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MdPlayCircleOutline } from 'react-icons/md';
import Sidebar from './Sidebar';
import './PlaylistVideos.css';


const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;

const PlaylistVideos = () => {
  const { playlistId } = useParams();
  const [playlistVideos, setPlaylistVideos] = useState([]);

  useEffect(() => {
    const fetchPlaylistVideos = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlistId}&key=${API_KEY}`
        );
        const data = await response.json();
        const videos = data.items;
        const videoIds = videos.map((video) => video.snippet.resourceId.videoId).join(',');
        const viewsResponse = await fetch(
          `https://www.googleapis.com/youtube/v3/videos?part=statistics&id=${videoIds}&key=${API_KEY}`
        );
        const viewsData = await viewsResponse.json();
        const videoViews = viewsData.items.reduce((obj, item) => {
          obj[item.id] = item.statistics.viewCount;
          return obj;
        }, {});
        setPlaylistVideos(videos.map((video) => ({ ...video, views: videoViews[video.snippet.resourceId.videoId] })));
      } catch (error) {
        console.error('Error fetching playlist videos:', error);
      }
    };

    fetchPlaylistVideos();
  }, [playlistId]);

  return (
    <div className='playlist-body'>
      <Link to='/library' >
        <h2 className='playlist-link'>Back To Library</h2>
      </Link>
      <div className="playlist-videos-container">
        <Sidebar />
        <section className="playlist-videos-section">
          {playlistVideos.map((video) => (
            <div key={video.id}>
              {video.snippet.thumbnails && video.snippet.thumbnails.default && (
                <Link to={`/library/videos-play/${video.snippet.resourceId.videoId}`}>
                  <div className="video-thumbnail">
                    <img src={video.snippet.thumbnails.high.url} alt={video.snippet.title} />
                    <div className="video-icon-container">
                      <MdPlayCircleOutline className="playlist-video-icon" />
                    </div>
                  </div>
                </Link>
              )}
              <p className='playlist-para-title'>{video.snippet.title}</p>
              {video.views && (
                <p className='playlist-para-views'>{`${video.views} views`}</p>
              )}
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default PlaylistVideos;
