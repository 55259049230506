import './ChiefVideos.css';
import images from '../../constants/images';


  const ChiefVideos = () => {
    return (
      <div className='chief-vid-body'>
        <div className='row-vid-chief'>
          <div className='left-vid-chief'>
          <img src={images.profile01} alt="chief"/>
            <div className='chief-card-main'>
              <h2 className='chief-vid-title'>the story of nature boy</h2> 
              <p className='chief-para-main'> 3 God's impact has been profound, as he has enabled innumerable individuals to liberate themcellves from the constraints of their past and embrace their complete potential. His legacy embodies optimism, perseverance, and transformation, serving as a remarkable demonstration of the boundless opportunities that exist within each of us.</p>
              <a href="https://www.youtube.com/watch?v=dNsU46Ykj1o&t=2s" target="_blank" rel="noopener noreferrer"> 
              <button className="play-btnM">Explore</button>
              </a>
            </div>
          </div>
          <div className='right-vid-chief'>
            <div className='content-vid-chief'>
              <div className='small-img-vid'>
                <img src={images.chiefstory2img} alt="chief"/>
                <div className='chief-card chief-card-two'>
                  <h2 className='left-vid-title'>Where are we located?</h2>
                  <p className='chief-para'> 3 God breaks down the science of where we are.</p>
                  <a href="https://www.youtube.com/watch?v=-BSuUmRwcMs" target="_blank" rel="noopener noreferrer"> 
              <button className="play-btnM">Explore</button>
              </a>
                </div>
              </div>
              <div className='small-img-vid'>
                <img src={images.chiefstory4img} alt="chief"/>
                <div className='chief-card chief-card-three'>
                  <h2 className='left-vid-title'>3 God's Pride and joy</h2>
                  <p className='chief-para'> How 3 God truely treat his wombman.</p>
                  <a href="https://www.youtube.com/watch?v=_jgNrFagQ_g" target="_blank" rel="noopener noreferrer"> 
              <button className="play-btnM">Explore</button>
              </a>
                </div>
              </div>
              <div className='small-img-vid'>
                <img src={images.chiefstory3img} alt="chief"/>
                <div className='chief-card chief-card-four'>
                  <h2 className='left-vid-title'>Love is understanding</h2>
                  <p className='chief-para'> Whats understood doesnt need to be explained .</p>
                  <a href="https://www.youtube.com/watch?v=q0SYMux6FL8" target="_blank" rel="noopener noreferrer"> 
              <button className="play-btnM">Explore</button>
              </a>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

  )
}

  export default ChiefVideos