import './HomeChief.css';
import { Link } from 'react-router-dom';
import images from '../../constants/images';
import {motion} from 'framer-motion';
import ParticleBackground from '../../components/Particles/ParticleBackground';

const HomeChief = () => {
  
    return (
      <motion.div className='chief-body'>
        <div className='row-chief'>
          <div className='left-chief'>
            <motion.img className='profile-chief'
              initial={{opacity:0}}
              whileInView={{opacity:1}}
              transition={{delay:.8, duration:1}}
              src={images.profile01} alt='chief'/>
              
          </div>
          <div className='right-chief'>
            <div className='content-chief-main'>
              <h3 className='chief-title'>3 God's word</h3>
              <img src={images.spoon} alt='feather' className='feather-img'/>
                <motion.h1 
                  initial={{opacity:0}}
                  whileInView={{opacity:3}}
                  transition={{delay:1, duration:1.2}}
                  className='chief-subtitle'>Knowledge of Cellf
                </motion.h1>
                  <p className='p__opensans'>3 God, a divine masculine melanated king, is a testament to the power of perseverance and the indomitable hueman spirit. Despite experiencing unimaginable hardships as a child, including the loss of both his parents and periods of homelessness, 3 God never lost sight of his purpose. His unwavering faith in his spirituality and commitment to his own persunal growth allowed him to transcend his circumstances and become a beacon of hope for others...
                  </p>
                 
                 
                  <p className="p__opensans">3ligio 3ishop</p> 
                   <p className="p__opensans">
                  Th3 Most Honorable Prophet 3 God
                  </p> 
                  <Link to='/threegod'>
                  <button className='chief__button_home'>Read More</button>
                  </Link>
               </div>
            <ParticleBackground />
        </div>
      </div>
    </motion.div>
  );
};

export default HomeChief;