import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {format} from 'date-fns';
import {PortableText} from '@portabletext/react';
import './BlogPostDetails.css';
import {client} from '../../lib/client';
import { FiFacebook, FiYoutube, FiInstagram, } from 'react-icons/fi';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { SlSocialSpotify } from 'react-icons/sl';
import images from '../../constants/images';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop'
import { Link } from 'react-router-dom';


export default function BlogPostDetails(props) {
  const [blogpost, setBlogpost] = useState ([]);
  const {slug} =useParams ()

  useEffect(() => {
    client.fetch(
      `*[slug.current == "${slug}"] {
        title,
        slug,
        publishedAt,
        content,
        mainImage {
          asset->{
            _id, 
            url,
          },
          alt,
        },
        author->{
          name,
          image {
            asset->{
              url
            },
            alt
          },
          bio
        }
      }`  
    ).then((data) => {
    setBlogpost(data[0]);
    })
    .catch(console.error)
  }, [slug])

  useEffect(() => {
    document.title = `Reading | ${blogpost.title}`
  }, [blogpost.title])

  useEffect(() => {
    document.body.classList.add('white-background');
    return () => {
      document.body.classList.remove('white-background');
    };
  }, []);

  return <>
    {blogpost && <section className='post-header'>
    <div className='header-content-blog post-container-blog'>
    <Link to='/blog'className='back-home'>Back to Blogs</Link>
      <h1 className='header-title-blog'>{blogpost.title}</h1>
      {blogpost.mainImage && 
      <img src={blogpost.mainImage.asset.url} 
      alt={blogpost.mainImage.alt} className='header-img-blog'/>}
      <section className='post-content-blog post-container-blog'>
      {blogpost.author ? (
      <p className='post-text-blog'> 
      By {blogpost.author.name} {blogpost.publishedAt && 
      <>&middot; {" "}
      {format(new Date(blogpost.publishedAt), 'dd MMMM yyyy')}
      </>}
      </p>
      ) : null}
      <div className='post-para-blog'><PortableText value={blogpost.content} /></div> 
      </section>
    </div>

  {blogpost.author && 
  <section className='author-details-info'>
    <div className='author-info-blog author-content-blog'>
      <div className='author-image-container'>
        <img src={blogpost.author.image.asset.url} 
        alt={blogpost.author.image.alt} className='author-image-blogpost'/>
        <div className='author-text-blogpost'>
          <h2 className='author-name-blogpost'>By {blogpost.author.name}</h2>
          <img src={images.spoon} alt='feather' className='feather-post-img'/>
          <div className='author-para-blogpost'><PortableText value={blogpost.author.bio}/>
          </div>
        </div>
      </div>
      <div className="footerblog-icon-links">
      <a href="https://m.facebook.com/eligio.bishop.92/" target="_blank" rel="noreferrer" ><FiFacebook /></a>
            <a href="https://www.youtube.com/@NatureboyTv" target="_blank" rel="noreferrer" ><FiYoutube /></a>
            <a href="https://instagram.com/natureboytv?igshid=OGQ2MjdiOTE" target="_blank" rel="noreferrer" ><FiInstagram /></a>
            <a href='https://open.spotify.com/artist/1D0MeqdWq34W1oIMBHS8dr?si=vKay0JDyTgaKTFYw-NhqAQ' target='_blank' rel='noreferrer'>
              <SlSocialSpotify className='spotify-icon'/> </a>
            <a href='https://cash.app/$3ligio13ishop' target='_blank' rel='noreferrer'>
              <MdOutlineAttachMoney className='cash-app'/></a>
      </div> 
      <div className="footer__copyright">
        <p className="p__opensans">2023 ImagineNation. All Rights reserved.</p>
    </div>
    </div>
  </section>
  }
  <ScrollToTop/>
  </section>
  }
</>;
}
