import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyDOQmZTbY8tRlLU-_ao02dbDFSRHrw4Kt0",
  authDomain: "imaginenation-react-app-2e8f4.firebaseapp.com",
  databaseURL: "https://imaginenation-react-app-2e8f4-default-rtdb.firebaseio.com",
  projectId: "imaginenation-react-app-2e8f4",
  storageBucket: "imaginenation-react-app-2e8f4.appspot.com",
  messagingSenderId: "307890345920",
  appId: "1:307890345920:web:1bc197c452e6bdfaa35a25",
  measurementId: "G-FC412376FY"
};


const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
