import './Iyah.css';
import images from '../../../constants/images';
import {motion} from 'framer-motion';



const Iyah = () => {
  return (
    <div className='iyah-container-full'>
    <div className='iyah-container-main'>
      <div className='iyah-container-row'>
        <div className='iyah-card-two'>
          <motion.img
            src={images.iyahimage1} alt='iyah' className='malia-img'/>
        </div>
        <div className='iyah-card-two'>
          <h3 className='iyah-title'>The heart of ImagineNation</h3>
          <img src={images.spoon} alt='feather' className='feather-img'/>
          <motion.h1 
            initial={{opacity:0}}
            whileInView={{opacity:3}}
            transition={{delay:1, duration:1.2}}
            className='iyah-subtitle'>Queen Iyah Eńuph Eniyan
            </motion.h1>
              <p className='p__opensansA'>Queen Iyah Eńuph Eniyan is a sight to behold. She emanates a divine aura that immediately fills everyones hearts with warmth and comfort. Her nurturing nature is evident in every aspect of her being, from the gentle smile on her face to the way she moves with such grace and elegance. Queen Iyah Eńuph Eniyan is a master of the culinary arts, and her cooking is a true expression of love and devotion. The aromas that emanate from her kitchen are enchanting, and one cannot help but feel drawn to her comforting presence.
              </p>
              <br/>
              <p className="p__opensansA">Not only is Queen Iyah Eńuph Eniyan a skilled cook, but she also possesses a vast knowledge of esoteric wisdom and spiritual practices. Her love of esoteric knowledge is apparent in the way she speaks and moves. Her every step flows like a beautiful flower, rooted in the earth yet reaching towards the heavens. Her spirituality is a guiding light that illuminates the path for those who seek knowledge and wisdom. Grace and beauty are a true reflection of her inner radiance, and her presence is a gift to all who encounter her. In her embrace, one can feel the warmth and love of the divine feminine, and one cannot help but be transformed by her presence.
              </p>
            <div className='iyah-container-row'>
              <div className='iyah-card-one'>
                <img src={images.iyahimage4}  alt="iyah" className='iyah-img-card' />
                <div>
                </div>
              </div>
              <div className='iyah-card-one'>
                <img src={images.iyahimage2}  alt="iyah" className='iyah-img-card' />
                <div>
                </div>
              </div>
              <div className='iyah-card-one'>
                <img src={images.iyahimage3}  alt="iyah" className='iyah-img-card' />
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Iyah
