import React, { useEffect, useState } from 'react';
import { db, auth } from '../../firebase-config';
import {
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  serverTimestamp,
  query,
  orderBy,
  getDoc,
} from 'firebase/firestore';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import './CommentsVIP.css';
import NestedComments from './NestedComments';

const CommentsVIP = ({ onClose }) => {
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [username, setUsername] = useState('');
  const [updatedCommentId, setUpdatedCommentId] = useState('');
  const [updatedCommentText, setUpdatedCommentText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [activeReplyId, setActiveReplyId] = useState('');

  const currentUser = auth.currentUser;

  const getRandomColor = () => {
    const colors = ['#FF0000', '#097709', '#0000FF', '#7e0a7e', '#da9e1e', '#16adad'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (commentText.trim() === '') {
      return;
    }

    const commentsCollection = collection(db, 'vipComments');
    const newComment = {
      name: username,
      content: commentText,
      userId: currentUser.uid,
      timestamp: serverTimestamp(),
      likedBy: [],
      likes: 0,
      avatarColor: getRandomColor(),
      parentCommentId: '', 
      nested: false,
    };
    await addDoc(commentsCollection, newComment);

    setCommentText('');
  };

  const handleReply = (commentId) => {
    setActiveReplyId(commentId);
  };

  const closeNestedComments = () => {
    setActiveReplyId('');
  };

  const handleUpdate = async (commentId) => {
    if (updatedCommentText.trim() === '') {
      setUpdatedCommentId('');
      setUpdatedCommentText('');
      return;
    }

    const commentRef = doc(db, 'vipComments', commentId);
    await updateDoc(commentRef, { content: updatedCommentText });
    setUpdatedCommentId('');
    setUpdatedCommentText('');
  };

  const handleDelete = async (commentId) => {
    const commentRef = doc(db, 'vipComments', commentId);
    await deleteDoc(commentRef);
  };

  const handleLike = async (commentId) => {
    const commentRef = doc(db, 'vipComments', commentId);
    const commentSnapshot = await getDoc(commentRef);
    const commentData = commentSnapshot.data();

    const updatedLikedBy = [...commentData.likedBy, currentUser.uid];
    await updateDoc(commentRef, {
      likedBy: updatedLikedBy,
      likes: updatedLikedBy.length,
    });
  };

  const handleUnlike = async (commentId) => {
    const commentRef = doc(db, 'vipComments', commentId);
    const commentSnapshot = await getDoc(commentRef);
    const commentData = commentSnapshot.data();

    const updatedLikedBy = commentData.likedBy.filter((id) => id !== currentUser.uid);
    await updateDoc(commentRef, {
      likedBy: updatedLikedBy,
      likes: updatedLikedBy.length,
    });
  };

  useEffect(() => {
    const commentsCollection = collection(db, 'vipComments');
    const q = query(commentsCollection, orderBy('timestamp', 'desc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const commentsData = snapshot.docs.map((doc) => {
        const commentData = doc.data();
        return {
          id: doc.id,
          ...commentData,
          likes: commentData.likedBy.length,
        };
      });
      setComments(commentsData);
    });

    if (currentUser) {
      setUsername(currentUser.displayName || currentUser.email);
    }

    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  const closeModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  return (
    <div className={`comments-vip ${isModalOpen ? 'open' : ''}`}>
      <div className="comments-vip-container">
        <div className="comments-vip-header">
          <div className="header-title">
            <h2>Get In Touch</h2>
          </div>
        </div>
        {currentUser && (
          <div className="comment-input-container">
            <input
              type="text"
              className="comment-input"
              placeholder="Add a comment..."
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            />
            <div className="comment-buttons-container">
              <button type="submit" className="comment-submit" onClick={handleSubmit}>
                Post
              </button>
              <button className="close-button" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        )}
        <div className="comments-vip-body">
          <div className="comments-list">
            {comments
              .filter((comment) => !comment.parentCommentId)
              .map((comment) => {
               

                return (
                  <div key={comment.id} className="comment-vip">
                    {currentUser && (
                      <div className="vip-comment-avatar">
                        <div
                          className="vip-avatar-circle"
                          style={{ backgroundColor: comment.avatarColor }}
                        >
                          {comment.name && comment.name.charAt(0)}
                        </div>
                        <span className="vip-avatar-name">{comment.name}</span>
                        <span className="vip-comment-date">
                        {new Date(comment.timestamp?.toDate()).toLocaleString()}
                      </span>
                      </div>
                    )}
                    <div className="vip-comment-content">{comment.content}</div>
                    <div className="vip-comment-actions">
                      {comment.userId === currentUser?.uid ? (
                        <>
                          {updatedCommentId === comment.id ? (
                            <>
                              <input
                                type="text"
                                className="update-input"
                                value={updatedCommentText}
                                onChange={(e) => setUpdatedCommentText(e.target.value)}
                              />
                              <button
                                className="update-button"
                                onClick={() => handleUpdate(comment.id)}
                              >
                                Update
                              </button>
                              <button
                                className="cancel-button"
                                onClick={() => {
                                  setUpdatedCommentId('');
                                  setUpdatedCommentText('');
                                }}
                              >
                                Cancel
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="edit-button"
                                onClick={() => {
                                  setUpdatedCommentId(comment.id);
                                  setUpdatedCommentText(comment.content);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="delete-button"
                                onClick={() => handleDelete(comment.id)}
                              >
                                Delete
                              </button>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {comment.likedBy.includes(currentUser?.uid) ? (
                            <button
                              className="unlike-button"
                              onClick={() => handleUnlike(comment.id)}
                            >
                              <AiFillHeart className="heart-icon" />
                              {comment.likes}
                            </button>
                          ) : (
                            <button className="like-button" onClick={() => handleLike(comment.id)}>
                              <AiOutlineHeart className="heart-icon" />
                              {comment.likes}
                            </button>
                          )}
                          <button
                            className="nested-reply-button"
                            onClick={() => handleReply(comment.id)}
                          >
                            Reply
                          </button>
                        </>
                      )}
                    </div>
                    {activeReplyId === comment.id && (
                      <NestedComments parentCommentId={comment.id} onClose={closeNestedComments} />
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsVIP;