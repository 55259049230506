import './BooksPage.css';
import {motion} from 'framer-motion';


const BooksPage = () => {
  return (
  <motion.div className='Books-image'>
    <div className='books-content'>
        <h3>Authored Books</h3>
        <motion.h1
        initial={{opacity:0}}
        whileInView={{opacity:3}}
        transition={{delay:0.5, duration:1.2}}>Knowledge of Cellf</motion.h1>
        <p className='p__opensans books_main_page'> Along with an enormous catalog of video teachings online, 3 God has authored several books neatly organized, insightful, and highly educational, giving you the tools you need to guide you on your spiritual journey</p>
    </div>
  </motion.div>
  );
};
export default BooksPage