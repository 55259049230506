import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/UserAuthContext';
import './LogoutButton.css';


const LogoutButton = () => {
  const { currentUser, logout } = UserAuth();
  const navigate = useNavigate();


  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className='welcome-logout-container'>
      <p className='welcome-message'>Welcome, {currentUser ? currentUser.email : 'Guest'}</p>
      <div className='vertical-line'></div>
      <div className='login-button'>
        <button className='logout-button-btn' onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
};


export default LogoutButton;
