import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import { GiMayanPyramid } from 'react-icons/gi';

const CHANNEL_ID = 'UCyK4e88TWxbgOcBW_F08AIg';
const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;

const Sidebar = () => {
  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/playlists?part=snippet&maxResults=50&channelId=${CHANNEL_ID}&key=${API_KEY}`
        );
        const data = await response.json();
        setPlaylists(data.items);
      } catch (error) {
        console.error('Error fetching playlists:', error);
      }
    };

    fetchPlaylists();
  }, []);

  return (
    <div className="sidebar-container">
      <ul className="sidebar-list">
        {playlists.map(playlist => (
          <li key={playlist.id} className="sidebar-item">
            <Link to={`/library/playlist-videos/${playlist.id}`} className="sidebar-link">
            <GiMayanPyramid className="sidebar-icon"/> {playlist.snippet.title}
            </Link>
          </li>
        ))}
      </ul>
      <footer className='sidebar-footer'>Copyright © 2015<br/> NatureboyTV
      </footer>
    </div>
  );
};

export default Sidebar;
