import React from 'react';
import './Chief.css';
import images from '../../constants/images';
import {motion} from 'framer-motion';
import ParticleBackground from '../../components/Particles/ParticleBackground';
import chiefaudio from '../../assets/chiefaudio.mp3';
import { BsFillPlayFill, BsPauseFill } from 'react-icons/bs';


const Chief = () => {
  const [playAudio, setPlayAudio] = React.useState(false);
  const audioRef = React.useRef();

    return (
      <motion.div className='chief-body'>
        <div className='row-chief'>
          <div className='left-chief'>
            <motion.img 
              initial={{opacity:0}}
              whileInView={{opacity:1}}
              transition={{delay:.8, duration:1}}
              src={images.chief} alt='chief'/>
              
          </div>
          <div className='right-chief'>
            <div className='content-chief'>
              <h3 className='chief-title'>3 God's word</h3>
              <img src={images.spoon} alt='feather' className='feather-img'/>
                <motion.h1 
                  initial={{opacity:0}}
                  whileInView={{opacity:3}}
                  transition={{delay:1, duration:1.2}}
                  className='chief-subtitle'>Knowledge of Cellf
                </motion.h1>
                  <p className='p__opensans'>3 God, a divine masculine melanated king, is a testament to the power of perseverance and the indomitable hueman spirit. Despite experiencing unimaginable hardships as a child, including the loss of both his parents and periods of homelessness, 3 God never lost sight of his purpose. His unwavering faith in his spirituality and commitment to his own persunal growth allowed him to transcend his circumstances and become a beacon of hope for others.
                  </p>
                  <p className="p__opensans">God's journey is a story of transformation and evolution. Through his own spiritual awakening and diligent study of metaphysical, esoteric, and scientific disciplines, 3 God was able to unlock the secrets of the youniverse and harness it's power for the greater good. His tireless efforts to heal the wounds of others and create a tribe of like-minded individuals are a testament to his boundless compassion and profound wisdom.
                  </p>
                  <p className="p__opensans">When you encounter 3 God, you cannot help but be struck by his aura of grace and wisdom. His presence is both commanding and comforting, a testament to his mastery of the spiritual realm. Through his teachings on astrology, biology, quantum physics, melanin, and grounding, he has empowered countless individuals to break free from the chains of their past and step into their full potential.
                 </p>
                 <p className="p__opensans"> 3 God is The Messiah who has brought Divine Knowledge of Cellf to the earth and One will be able to access it through their Imagination.
                 </p>
                  <p className="p__opensans">3ligio 3ishop</p> 
                <p className="p__opensans">
                  Th3 Most Honorable Prophet, 3 God
                  </p> 
                  <audio
                    ref={audioRef}
                    src={chiefaudio}
                    type="audio/mp3"
                    loop
                    controls={false}
                    />
                  <button type="button" className='chief__button'
                    onClick={() => {
                    setPlayAudio(!playAudio);
                    if (playAudio) {
                    audioRef.current.pause();
                    } else {
                    audioRef.current.play();
                    }
                    }}>
                    {playAudio ? (
                    <BsPauseFill  color="#000" fontSize={30} />
                    ) : (
                    <BsFillPlayFill color="#000" fontSize={30} />
                    )}
                  </button>
            </div>
            <ParticleBackground />
        </div>
      </div>
    </motion.div>
  );
};

export default Chief;