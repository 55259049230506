import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/UserAuthContext";
import GoogleButton from 'react-google-button';
import './Login.css'
import Discretion from "./Discretion";
import { FiEye, FiEyeOff } from "react-icons/fi";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { loginUser, resetPassword, signInWithGoogle } = UserAuth();
  const navigate = useNavigate();

  const handleSignInWithGoogle = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signInWithGoogle();
      navigate("/home");
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await loginUser(email, password);
      navigate("/home");
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await resetPassword(email);
      alert("Password reset link sent to your email");
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  return (
    <div className="login-auth-container">
      <Discretion />
      {error && <p className="error-login">{error}</p>}
      <form onSubmit={handleSubmit} className="login-auth">
        <h2 className="auth-h2">Log in to your account</h2>
        <div className="auth-group">
          <label className="login-label" htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
          />
        </div>
        <div className="auth-group password-input-container">
          <label className="login-label" htmlFor="password">Password</label>
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input password-input"
          />
          <button
            type="button"
            className="password-toggle-btn"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FiEyeOff /> : <FiEye />}
          </button>
        </div>
        <button className="login-submit-btn" type="submit">Sign In</button>
      </form>
      <div className="forgot-password-container">
        <p>
          <button className="link-button" onClick={handleResetPassword}>
            Forgot Password?
          </button>
        </p>
      </div>
      <div className="or-divider">
        <span>or</span>
      </div>
      <div className="google-login-container">
        <GoogleButton onClick={handleSignInWithGoogle} />
        <div className="signup-auth">
          <p className="signup-auth-para">
            Don't have an account? <Link to="/signup">Sign Up</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
