import Login from '../components/LoginAuth/Login';
import SignUp from '../components/LoginAuth/SignUp';
import Home from '../routes/Home';
import ThreesPride from '../routes/ThreesPride';
import Books from '../routes/Books';
import About from '../routes/About';
import Contact from '../routes/Contact';
import { Routes, Route, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Library from '../routes/Library';
import Blog from './Blog';
import BlogPostDetails from '../Pages/BlogPage/BlogPostDetails';
import PlaylistVideos from '../Pages/LibraryPage/PlaylistVideos';
import LatestVideoUploads from '../Pages/LibraryPage/LatestVideoUploads';
import VideosPlay from '../Pages/LibraryPage/VideosPlay';
import { AuthContextProvider } from '../context/UserAuthContext';
import ProtectedRoutes from './ProtectedRoutes';

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <motion.div>
        <AuthContextProvider>
          <Routes location={location} key={location.pathname}>
            <Route path='/' element={<Login />} />
            <Route path='/signup' element={<SignUp />} />
            <Route element={<ProtectedRoutes />}>
              <Route path='/home' element={<Home />} />
              <Route path='/library' element={<Library />} />
              <Route path='/library/latest-uploads/:uploadsId' element={<LatestVideoUploads />} />
              <Route path='/library/playlist-videos/:playlistId' element={<PlaylistVideos />} />
              <Route path='/library/videos-play/:videosId' element={<VideosPlay />} />
              <Route path='/books' element={<Books />} />
              <Route path='/threegod' element={<About />} />
              <Route path='/threespride' element={<ThreesPride />} />
              <Route path='/blog' element={<Blog />} />
              <Route path='/blog/:slug' element={<BlogPostDetails />} />
              <Route path='/contact' element={<Contact />} />
            </Route>
          </Routes>
        </AuthContextProvider>
      </motion.div>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;