import spoon from '../assets/spoon.webp';
import chief from '../assets/chief.jpg';
import pyramid from '../assets/pyramid.png';
import pride3 from '../assets/pride3.jpg';
import pride2 from '../assets/pride2.jpg';
import pageimg8 from '../assets/pageimg8.jpg';
import book1 from '../assets/book1.jpg';
import book2 from '../assets/book2.jpg';
import book3 from '../assets/book3.jpg';
import book4 from '../assets/book4.jpg';
import book5 from '../assets/book5.jpg';
import book6 from '../assets/book6.jpg';
import chief1 from '../assets/chief1.jpg';
import chief2 from '../assets/chief2.jpg';
import chief3 from '../assets/chief3.jpg';
import chief4 from '../assets/chief4.jpg';
import chief5 from '../assets/chief5.jpg';
import chief6 from '../assets/chief6.jpg';
import chief7 from '../assets/chief7.jpg';
import chief8 from '../assets/chief8.jpg';
import chief9 from '../assets/chief9.jpg';
import chief10 from '../assets/chief10.jpg';
import chief11 from '../assets/chief11.jpg';
import chief12 from '../assets/chief12.jpg';
import chief13 from '../assets/chief13.jpg';
import chief14 from '../assets/chief14.jpg';
import chief15 from '../assets/chief15.jpg';
import chief16 from '../assets/chief16.jpg';
import chief17 from '../assets/chief17.jpg';
import chief18 from '../assets/chief18.jpg';
import chief19 from '../assets/chief19.jpg';
import chief20 from '../assets/chief20.jpg';
import chief21 from '../assets/chief21.jpg';
import chief22 from '../assets/chief22.jpg';
import chief23 from '../assets/chief23.jpg';
import chief24 from '../assets/chief24.jpg';
import chief25 from '../assets/chief25.jpg';
import chief26 from '../assets/chief26.jpg';
import chief27 from '../assets/chief27.jpg';
import chief28 from '../assets/chief28.jpg';
import chief29 from '../assets/chief29.jpg';
import chief30 from '../assets/chief30.jpg';
import chief31 from '../assets/chief31.jpg';
import chief32 from '../assets/chief32.jpg';
import chief33 from '../assets/chief33.jpg';
import chief34 from '../assets/chief34.jpg';
import chief35 from '../assets/chief35.jpg';
import chief36 from '../assets/chief36.jpg';
import chief37 from '../assets/chief37.jpg';
import chief38 from '../assets/chief38.jpg';
import chief39 from '../assets/chief39.jpg';
import chief40 from '../assets/chief40.jpg';
import chief41 from '../assets/chief41.jpg';
import chief42 from '../assets/chief42.jpg';
import chief43 from '../assets/chief43.jpg';
import chief44 from '../assets/chief44.jpg';
import chief45 from '../assets/chief45.jpg';
import chief46 from '../assets/chief46.jpg';
import chiefstory1img from '../assets/chiefstory1img.jpg';
import chiefstory2img from '../assets/chiefstory2img.jpg';
import chiefstory3img from '../assets/chiefstory3img.jpg';
import chiefstory4img from '../assets/chiefstory4img.jpg';
import maliaimage1 from '../assets/maliaimage1.jpg';
import maliaimage2 from '../assets/maliaimage2.jpg';
import maliaimage3 from '../assets/maliaimage3.jpg';
import maliaimage4 from '../assets/maliaimage4.jpg';
import iyahimage1 from '../assets/iyahimage1.jpg';
import iyahimage2 from '../assets/iyahimage2.jpg';
import iyahimage3 from '../assets/iyahimage3.jpg';
import iyahimage4 from '../assets/iyahimage4.jpg';
import efuruimage1 from '../assets/efuruimage1.jpg';
import efuruimage2 from '../assets/efuruimage2.jpg';
import efuruimage3 from '../assets/efuruimage3.jpg';
import efuruimage4 from '../assets/efuruimage4.jpg';
import faithimage1 from '../assets/faithimage1.jpg';
import faithimage2 from '../assets/faithimage2.jpg';
import faithimage3 from '../assets/faithimage3.jpg';
import faithimage4 from '../assets/faithimage4.jpg';
import Egypt5 from '../assets/Egypt5.jpg';
import profile01 from '../assets/profile01.webp';
import profile02 from '../assets/profile02.jpg';
import nature3 from '../assets/nature3.jpg';
import imaginenationlogo from '../assets/imaginenationlogo.png';
import fivescience from '../assets/fivescience.webp';
import biglogo from '../assets/biglogo.png';
import pride3mainimg from '../assets/pride3mainimg.jpg';


const images = {
  spoon,
  chief,
  pyramid,
  pride3,
  pride2,
  book1,
  book2,
  book3,
  book4,
  book5,
  book6,
  chief1,
  chief2,
  chief3,
  chief4,
  chief5,
  chief6,
  chief7,
  chief8,
  chief9,
  chief10,
  chief11,
  chief12,
  chief13,
  chief14,
  chief15,
  chief16,
  chief17,
  chief18,
  chief19,
  chief20,
  chief21,
  chief22,
  chief23,
  chief24,
  chief25,
  chief26,
  chief27,
  chief28,
  chief29,
  chief30,
  chief31,
  chief32,
  chief33,
  chief34,
  chief35,
  chief36,
  chief37,
  chief38,
  chief39,
  chief40,
  chief41,
  chief42,
  chief43,
  chief44,
  chief45,
  chief46,
  chiefstory1img,
  chiefstory2img,
  chiefstory3img,
  chiefstory4img,
  maliaimage1,
  maliaimage2,
  maliaimage3,
  maliaimage4,
  iyahimage1,
  iyahimage2,
  iyahimage3,
  iyahimage4,
  efuruimage1,
  efuruimage2,
  efuruimage3,
  efuruimage4,
  faithimage1,
  faithimage2,
  faithimage3,
  faithimage4,
  Egypt5,
  profile01,
  profile02,
  nature3,
  imaginenationlogo,
  fivescience,
  pageimg8,
  biglogo,
  pride3mainimg,
};

export default images;