import { useEffect } from "react";
import {analytics } from "../firebase-config";
import { logEvent } from "firebase/analytics";
import React, { lazy, Suspense } from 'react';
import LogoutButton from '../components/LoginAuth/LogoutButton';

const ContactForm = lazy(() => import('../Pages/ContactPage/ContactForm'));

const Contact = () => {
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: window.location.pathname,
      page_title: document.title,
    });
  }, []);
  return (
    <div>
      <LogoutButton/>
      <Suspense fallback={<div>Loading...</div>}>
        <ContactForm/>
      </Suspense>
    </div>
  );
};

export default Contact;
