import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import './LatestVideoUploads.css';

const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;

const LatestVideoUploads = () => {
  const { uploadsId } = useParams();
  const [video, setVideo] = useState(null);
  const [statistics, setStatistics] = useState(null);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${uploadsId}&key=${API_KEY}`
        );
        if (!response.ok) {
          throw new Error('Error fetching video: Network response was not ok');
        }
        const data = await response.json();
        if (data.items && data.items.length > 0) {
          setVideo(data.items[0]);
        } else {
          throw new Error('Error fetching video: No video found');
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchVideo();

    const fetchStatistics = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/videos?part=statistics&id=${uploadsId}&key=${API_KEY}`
        );
        if (!response.ok) {
          throw new Error('Error fetching statistics: Network response was not ok');
        }
        const data = await response.json();
        if (data.items && data.items.length > 0) {
          setStatistics(data.items[0].statistics);
        } else {
          throw new Error('Error fetching statistics: No statistics found');
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchStatistics();
  }, [uploadsId]);

  return (
    <div className='video-player-body'>
      <div className='video-player'>
        {video && statistics ? (
          <>
            <Link to='/library' >
              <h2 className='new-videos-link'>Back To New Videos</h2>
            </Link>
            <iframe
              title={video.snippet.title}
              src={`https://www.youtube.com/embed/${uploadsId}`}
              allowFullScreen
              className="video-iframe"
            />
            <div className='latest-vid-content'>
            <h1 className='latest-vid-title'>{video.snippet.title}</h1>
            <p className='latest-vid-para'>{video.snippet.description}</p>
            <p className='latest-vid-views'>Views: {statistics.viewCount}</p>
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default LatestVideoUploads;
