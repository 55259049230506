import React, {useState, useEffect} from 'react'
import './BlogPost.css'
import {Link} from 'react-router-dom'
import {client} from '../../lib/client';
import {format} from 'date-fns';
import Footer from '../../components/Footer/Footer';
import ParticleBackground from '../../components/Particles/ParticleBackground';


const BlogPost = () => {
  const [stories, setStories] = useState([]);

  useEffect(() => {
    client.fetch(
      `*[_type == "post"] {
        title,
        slug,
        publishedAt,
        content,
        mainImage {
          asset->{
            _id, 
            url,
          },
          alt,
        },
        author->{
          name,
          image {
            asset->{
              url
            },
            alt
          }
        }
      } | order(publishedAt desc)`  
    ).then((data) => {
    setStories(data)
    })
  }, [])


  return (
  <>
  <section className="blogpost-container-two">
  <div className="blog-row-two">
    {stories.map((story) => (
      <Link to={`/blog/${story.slug.current}`} key={story.slug.current}>
        <div className="card-03">
          {story.mainImage && (
            <img
              src={story.mainImage.asset.url}
              alt={story.mainImage.alt}
              loading="lazy"
              className="blog-img-two"
            />
          )}
          <div className="author-info">
          {story.author?.image && (
                  <img 
                    src={story.author.image.asset.url} 
                    alt={story.author.image.alt} 
                    className='author-img' 
                  />
                )}
          <p className='blog-author'>By {story.author?.name} &middot; {format(new Date(story.publishedAt), 'dd MMMM yyyy')}</p>
            <h2 className='blog-two'>
              {story.title}</h2>
              <p className='blog-para-two'>{`${story.content[0].children[0].text.substring(0, 120)}...`}</p>
          </div> 
        </div>
      </Link>
    ))}
  </div>
</section>
    <ParticleBackground/>
 <Footer/>
  </>
)}

export default BlogPost
