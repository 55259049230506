import { useEffect } from "react";
import {analytics } from "../firebase-config";
import { logEvent } from "firebase/analytics";
import {  motion } from 'framer-motion';
import ThreesPrideMain from '../Pages/ThreesPridePage/ThreesPrideMain';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import Iyah from '../Pages/ThreesPridePage/Iyah/Iyah';
import Efuru from '../Pages/ThreesPridePage/Efuru/Efuru';
import Faith from '../Pages/ThreesPridePage/Faith/Faith';
import Footer from '../components/Footer/Footer';
import Malia from '../Pages/ThreesPridePage/Malia/Malia';
import LogoutButton from '../components/LoginAuth/LogoutButton';

const ThreesPride = () => {
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: window.location.pathname,
      page_title: document.title,
    });
  }, []);
  return (
    <motion.div initial=
      {{width: 0}} 
      animate={{width: "100%"}} 
      exit={{x: "100%", transition: {duration: 0.1}}}>
        <LogoutButton/>
      <ThreesPrideMain/>
      <Malia />
      <Iyah />
      <Efuru />
      <Faith />
      <Footer />
      <ScrollToTop />
    </motion.div>
  )
}

export default ThreesPride
