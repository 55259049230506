import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {client} from '../../lib/client';
import './BlogMain.css'


const BlogMain = () => {
  const [stories, setStories] = useState([]);

  useEffect(() => {
    client.fetch(
      `*[_type == "post"] {
        title,
        slug,
        publishedAt,
        content,
        mainImage {
          asset->{
            _id, 
            url,
          },
          alt,
        },
        author->{
          name,
          image {
            asset->{
              url
            },
            alt
          }
        }
      } | order(publishedAt desc) [0...3]`  
    ).then((data) => {
    setStories(data)
    })
  }, [])

  const handleButtonClick = () => {
    window.scrollTo(0, 0);
  };


    return (
      <div className='full-container'>
        {!stories ? (
        <h2>Loading...</h2>
        ) : (<>
        {stories[0] && (
        <section >
          <article>
            <h1 className='title-bloggie'>Blog posts: Read Our Daily Bread </h1>
            <div className='blog-container'>
              <div className='blog-row'>
                <div className='card-01'>
                  {stories[0].mainImage && <img 
                  src={stories[0].mainImage.asset.url} 
                  alt={stories[0].mainImage.alt} className='blog-img'/> 
                  }
                  <div className='blog-content-one'>
                    <h1 className='blog-title'>{stories[0].title}</h1>
                    <p className='blog-para'>{stories[0].content[0].children[0].text.substring(0, 316)}...</p>
                    <div>
                    <button className="blog-button" onClick={handleButtonClick}>
                      <Link to='/blog'>Read</Link>
                    </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
        )}
        </>
        )}
    </div>
    )
  }
  
  export default BlogMain; 