import './AboutPage.css'
import {motion} from 'framer-motion';


const AboutPage = () => {
  return (
    <motion.div className='chief-hero-image'>
      <div className='chief-content'>
        <h3>To my Reflections</h3>
          <motion.h1
          initial={{opacity:0}}
          whileInView={{opacity:1}}
          transition={{delay:0.5, duration:1.2}}> About 3 god</motion.h1>
          <p className='p__opensans'>"Whoever acknowledges me before others, I will acknowledge before my father in heaven"</p><span>~ Mathew 10:52</span>
      </div>
    </motion.div>
  )
}

export default AboutPage