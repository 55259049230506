import './BooksOne.css';
import images from '../../constants/images';
import {motion} from 'framer-motion';
import ParticleBackground from '../../components/Particles/ParticleBackground';



const BooksOne = () => {
  return (
    <motion.div className='body-book'>
    <div className='row-book'>
      <div className='left-book'>
        <img src={images.book1} alt='cookbook'/>
      </div>
      <div className='right-book'>
        <div className='content-book'>
          <h3 className='book-title'>Your body is your temple</h3>
          <img src={images.spoon} alt='feather' className='feather-img-books'/>
            <motion.h1 
              initial={{opacity:0}}
              whileInView={{opacity:3}}
              transition={{delay:1, duration:1.2}}
              className='book-subtitle'>The B6 Diet Cook book
            </motion.h1>
              <p className='p__opensans'>The Ultimate Cookbook by ImagineNation makes it easy to switch to an organic earth-based diet. The B6 Diet offers balanced meals with fruits, veggies, nuts, and seeds. This culinary classic provides delicious recipes and valuable information on food science, Vitamin B6, and reconnecting with Nature.
              </p>
              <a href='https://books.apple.com/us/book/the-b6-diet-cook-book/id1405337761' target='blank'rel='noreferrer'>
                <button type="button" className='content__button'>Learn More</button>
              </a>
        </div>
        <ParticleBackground />
      </div>
    </div>
    </motion.div>
  )
}

export default BooksOne;