import './ThreesPrideMain.css';
import {motion} from 'framer-motion';


const ThreesPrideMain = () => {
  return (
    <motion.div className='threes-pride-hero'>
      <div className='threes-pride-content'>
        <h3>The Divine Feminine</h3>
        <motion.h1
        initial={{opacity:0}}
        whileInView={{opacity:1}}
        transition={{delay:0.5, duration:1.2}}> 3 God's Pride</motion.h1>
        <p className='p__opensans threes__pride_main'>The divine wombman seeks a man who is god-like, she breaks the illusion of separation, and honors her body. A divine wombman is all natural, eats organic and lives a holistic lifestyle. A divine wombman reproduces and cultivates her man's mind. She is emotionally intelligent and loyal to the divine masculine. A Divine wombman is virtuous</p>
      </div>
    </motion.div>
    )
  }
  

export default ThreesPrideMain


