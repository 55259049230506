import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GiMayanPyramid } from 'react-icons/gi';
import images from '../../constants/images';
import './Navbar.css';

// Lazy load the DonateModal component
const DonateModal = lazy(() => import('../DonateModal/DonateModal'));

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    openModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto');
  }, [openModal]);

  return (
    <div className='app__navbar'>
      <div className='app__navbar-logo'>
        <Link to='/home'>
          <img src={images.imaginenationlogo} alt='app logo' />
        </Link>
      </div>
      <ul className='app__navbar-links'>
        <li className='p__opensans'>
          <Link to='/home'>Home</Link>
        </li>
        <li className='p__opensans'>
          <Link to='/library'>Library</Link>
        </li>
        <li className='p__opensans'>
          <Link to='/books'>Books</Link>
        </li>
        <li className='p__opensans'>
          <Link to='/blog'>Blog</Link>
        </li>
        <li className='p__opensans'>
          <Link to='/threegod'>3 God</Link>
        </li>
        <li className='p__opensans'>
          <Link to='/threespride'>3's Pride</Link>
        </li>
        <li className='p__opensans'>
          <Link to='/contact'>Contact</Link>
        </li>
        <button className='button-navA' onClick={() => setOpenModal(true)}>
          To Donate
        </button>
      </ul>
      {screenWidth <= 1150 && (
        <h2 className='imagine-nation-header'>Imagine Nation</h2>
      )}
      <div className='app__navbar-smallscreen'>
        <GiHamburgerMenu
          color='#fff'
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className='app__navbar-smallscreen_overlay flex_center slide-bottom'>
            <GiMayanPyramid
              fontSize={27}
              className='overlay__close'
              onClick={() => setToggleMenu(false)}
            />
            <ul
              className='app__navbar-smallscreen_links'
              onClick={() => setToggleMenu(false)}
            >
              <li className='p__opensans'>
                <Link to='/home'>Home</Link>
              </li>
              <li className='p__opensans'>
                <Link to='/library'>Library</Link>
              </li>
              <li>
                <Link to='/books'>Books</Link>
              </li>
              <li className='p__opensans'>
                <Link to='/blog'>Blog</Link>
              </li>
              <li className='p__opensans'>
                <Link to='/threegod'>3 God</Link>
              </li>
              <li className='p__opensans'>
                <Link to='/threespride'>3's Pride</Link>
              </li>
              <li className='p__opensans'>
                <Link to='/contact'>Contact</Link>
              </li>
              <li>
                <button
                  className='button-nav'
                  onClick={() => setOpenModal(true)}
                >
                  Donate
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <DonateModal open={openModal} onClose={() => setOpenModal(false)} />
      </Suspense>
    </div>
  );
};

export default Navbar;
