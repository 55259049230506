import './HomeScience.css';
import {Link} from 'react-router-dom';
import ParticleBackground from '../../components/Particles/ParticleBackground';
import images from '../../constants/images';


const HomeScience = () => {
  return (
    <div className='science-main-container'>
    <div className='science-container'>
      <div className='textblock'>
        <h3 className='science-subtitle'>The keys to unlocking the youniverse</h3>
        <img src={images.spoon} alt='feather' className='science-feather-img'/>
        <h1 className='science-title'>The Five Sciences</h1>
        <p className='science-para'>Our belief is based in science. To know thycellf is to know the 5 sciences: Astrology, Biology, Quantum Physics, Melanin and Grounding.
        </p>
        <p className='science-para'>Through ImagineNation's extensive library of knowledge, one can explore the depths of each of these sciences and be transformed. Each science has its unique perspective on the world, and together they reveal a grander, more profound understanding of the youniverse and our place in it.</p>
        <Link to='/library'>
          <button className='science-button'>Explore</button>
        </Link>
        </div>
      <img src={images.fivescience} alt='astrology' className='astrology-img'/>
    </div>
    <ParticleBackground/>
    </div>
  )
}

export default HomeScience