import images from '../../constants/images';
import {motion} from 'framer-motion';


const BooksFour = () => {
  return (
    <motion.div className='book-cover'>
    <div className='app__bgO '>
    <div className='body-book'>
      <div className='row-book'>
        <div className='left-book'>
        <img src={images.book4} alt='cookbook'/>
        </div>
        <div className='right-book'>
          <div className='content-book'>
            <h3 className='book-title'>Your Body is your temple</h3>
            <img src={images.spoon} alt='feather' className='feather-img-books'/>
              <motion.h1 
                initial={{opacity:0}}
                whileInView={{opacity:3}}
                transition={{delay:1, duration:1.2}}
                className='book-subtitle'>Exposing The Food Industry
              </motion.h1>
                <p className='p__opensans'>In “Master Chief: Exposing The Food Industry,” The Most Honorable Grand Master Chief Eligio The Christ exposes the deadly truth about genetically-modified foods that are being sold in supermarkets across The United States and other parts of the world.
                </p>
                <a href='https://books.apple.com/us/book/master-chief-exposing-the-food-industry/id1484271579' target='blank'rel='noreferrer'> <button type="button" className='content__button'>Learn More</button>
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </motion.div>
  );
};

export default BooksFour;