import React, { useState } from 'react';
import './VIPModal.css';
import CommentsVIP from './CommentsVIP';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const VIPModal = ({ onClose }) => {
  const [password, setPassword] = useState('');
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [isCommentsVIPVisible, setIsCommentsVIPVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (password === process.env.REACT_APP_VIP_PASSWORD) {
      setIsPasswordCorrect(true);
      setIsCommentsVIPVisible(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
    
    setPassword('');
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const closeCommentsVIPModal = () => {
    setIsCommentsVIPVisible(false);
    onClose();
  };

  return (
    <div className="vip__container">
      {(!isPasswordCorrect || !isCommentsVIPVisible) && (
        <div className="vip__content">
          <h2>Enter Password</h2>
          <form onSubmit={handleFormSubmit}>
            <label>
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
              />
              {isPasswordVisible ? (
                <AiOutlineEyeInvisible
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <AiOutlineEye
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                />
              )}
            </label>
            <button type="submit">Submit</button>
            <button onClick={onClose}>Close</button>
          </form>
        </div>
      )}

      {isPasswordCorrect && isCommentsVIPVisible && (
        <CommentsVIP onClose={closeCommentsVIPModal} />
      )}
    </div>
  );
};

export default VIPModal;
