import React, { useState, useEffect } from 'react';
import {
  collection,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
  deleteDoc,
  query,
  where,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import { db, auth } from '../../firebase-config';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import './NestedComments.css';

const NestedComments = ({ parentCommentId, onClose }) => {
  const [nestedComments, setNestedComments] = useState([]);
  const [replyText, setReplyText] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const currentUser = auth.currentUser;

  const getRandomColor = () => {
    const colors = ['#FF0000', '#097709', '#0000FF', '#7e0a7e', '#da9e1e', '#16adad'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const [editingCommentId, setEditingCommentId] = useState('');
  const [editText, setEditText] = useState('');

  const handleEdit = (commentId, commentContent) => {
    setEditingCommentId(commentId);
    setEditText(commentContent);
    setIsEditing(true);
  };

  const handleUpdate = async (commentId) => {
    const commentRef = doc(db, 'vipComments', commentId);
    await updateDoc(commentRef, { content: editText });
    setEditingCommentId('');
    setEditText('');
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setEditingCommentId('');
    setEditText('');
    setIsEditing(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (replyText.trim() === '') {
      return;
    }

    const commentsCollection = collection(db, 'vipComments');
    const newComment = {
      name: currentUser.displayName || currentUser.email,
      content: replyText,
      userId: currentUser.uid,
      parentCommentId: parentCommentId,
      timestamp: serverTimestamp(),
      likedBy: [],
      likes: 0,
      avatarColor: getRandomColor(),
    };
    await addDoc(commentsCollection, newComment);

    setReplyText('');
  };

  const handleLike = async (commentId) => {
    const commentRef = doc(db, 'vipComments', commentId);
    const commentSnapshot = await getDoc(commentRef);
    const commentData = commentSnapshot.data();

    const updatedLikedBy = [...commentData.likedBy, currentUser.uid];
    await updateDoc(commentRef, {
      likedBy: updatedLikedBy,
      likes: updatedLikedBy.length,
    });
  };

  const handleUnlike = async (commentId) => {
    const commentRef = doc(db, 'vipComments', commentId);
    const commentSnapshot = await getDoc(commentRef);
    const commentData = commentSnapshot.data();

    const updatedLikedBy = commentData.likedBy.filter((id) => id !== currentUser.uid);
    await updateDoc(commentRef, {
      likedBy: updatedLikedBy,
      likes: updatedLikedBy.length,
    });
  };

  useEffect(() => {
    const commentsCollection = collection(db, 'vipComments');
    const q = query(
      commentsCollection,
      where('parentCommentId', '==', parentCommentId),
      orderBy('timestamp', 'asc')
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const commentsData = snapshot.docs.map((doc) => {
        const commentData = doc.data();
        return {
          id: doc.id,
          ...commentData,
          likes: commentData.likedBy.length,
        };
      });
      setNestedComments(commentsData);
    });

    return () => {
      unsubscribe();
    };
  }, [parentCommentId]);

  const handleDelete = async (commentId) => {
    const commentRef = doc(db, 'vipComments', commentId);
    await deleteDoc(commentRef);
  };

  return (
    <div className="nested-comments">
      <div className="nested-comment-input-container">
        <input
          type="text"
          className="nested-comment-input"
          placeholder="Reply to this comment..."
          value={replyText}
          onChange={(e) => setReplyText(e.target.value)}
        />
        <button type="submit" className="nested-comment-submit" onClick={handleSubmit}>
          Reply
        </button>
        <button className="nested-close-button" onClick={onClose}>
          Close
        </button>
      </div>
      <div className="nested-comments-list">
        {nestedComments.map((comment) => (
          <div key={comment.id} className="nested-comment-vip">
            <div className="nested-vip-comment-avatar">
              <div
                className="nested-vip-avatar-circle"
                style={{ backgroundColor: comment.avatarColor }}
              >
                {comment.name && comment.name.charAt(0)}
              </div>
              <span className="nested-vip-avatar-name">{comment.name}</span>
              <span className="nested-vip-comment-date">
                {new Date(comment.timestamp?.toDate()).toLocaleString()}
              </span>
            </div>
            {editingCommentId === comment.id ? (
              <input
                type="text"
                className="nested-comment-input"
                value={editText}
                onChange={(e) => setEditText(e.target.value)}
              />
            ) : (
              <div className="nested-vip-comment-content">{comment.content}</div>
            )}
            <div className="nested-vip-comment-actions">
              {comment.userId === currentUser?.uid ? (
                <>
                  {editingCommentId === comment.id ? (
                    <>
                      <button className="nested-update-button" onClick={() => handleUpdate(comment.id)}>
                        Update
                      </button>
                      <button className="nested-cancel-button" onClick={handleCancelEdit}>
                        Cancel
                      </button>
                    </>
                  ) : (
                    <button className="nested-edit-button" onClick={() => handleEdit(comment.id, comment.content)}>
                      Edit
                    </button>
                  )}
                  {!isEditing && (
                    <button className="nested-delete-button" onClick={() => handleDelete(comment.id)}>
                      Delete
                    </button>
                  )}
                </>
              ) : (
                <>
                  {comment.likedBy.includes(currentUser?.uid) ? (
                    <button className="nested-unlike-button" onClick={() => handleUnlike(comment.id)}>
                      <AiFillHeart className="nested-heart-icon" />
                      {comment.likes}
                    </button>
                  ) : (
                    <button className="nested-like-button" onClick={() => handleLike(comment.id)}>
                      <AiOutlineHeart className="nested-heart-icon" />
                      {comment.likes}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NestedComments;
