import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;

const VideosPlay = () => {
  const { videosId } = useParams();
  const [video, setVideo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet%2Cstatistics&id=${videosId}&key=${API_KEY}`
        );
        const data = await response.json();
        setVideo(data.items[0]);
      } catch (error) {
        console.error('Error fetching video:', error);
      }
    };

    fetchVideo();
  }, [videosId]);

  if (!video) {
    return <div>Loading...</div>;
  }

  const videoUrl = `https://www.youtube.com/embed/${videosId}`;

  return (
    <div className='video-player-body'>
      <div className='video-player'>
        <h2 className='new-videos-link' onClick={() => navigate(-1)}>
          Back To Playlists
        </h2>
        <iframe
          width='560'
          height='315'
          src={videoUrl}
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          className="video-iframe"
        />
        <div className='latest-vid-content'>
        <h1 className='latest-vid-title'>{video.snippet.title}</h1>
        <p className='latest-vid-para'>{video.snippet.description}</p>
        <p>Views: {video.statistics.viewCount}</p>
        </div>
      </div>
    </div>
  );
};

export default VideosPlay;
