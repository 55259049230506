import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./styles.css";
import { EffectCoverflow, Pagination } from "swiper";
import images from '../../constants/images';
import {motion} from 'framer-motion';


const imagePaths = [
  images.chief8,
  images.chief3,
  images.chief15,
  images.chief17,
  images.chief16,
  images.chief14,
  images.chief13,
  images.chief9,
  images.chief7,
  images.chief2,
  images.chief4,
  images.chief10,
  images.chief1,
  images.chief12,
  images.chief11,
  images.chief6,
  images.chief18,
  images.chief19,
  images.chief20,
  images.chief21,
  images.chief22,
  images.chief23,
  images.chief24,
  images.chief25,
  images.chief26,
  images.chief27,
  images.chief28,
  images.chief29,
  images.chief30,
  images.chief31,
  images.chief32,
  images.chief33,
  images.chief34,
  images.chief35,
  images.chief36,
  images.chief37,
  images.chief38,
  images.chief39,
  images.chief40,
  images.chief41,
  images.chief42,
  images.chief43,
  images.chief44,
  images.chief45,
  images.chief46,
];

export default function App() {
  return (
    <>
    <motion.div className='chief-text-image'>
      <h3>Life is a Download</h3>
      <img src={images.spoon} alt='chief'/>
      <motion.h1 initial={{opacity:0}}
      whileInView={{opacity:1}}
      transition={{delay:1, duration:1.2}}>To thy owncellf be true</motion.h1>
    </motion.div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={4}
        coverflowEffect={{
          rotate: 20,
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: true,
        }
      }
        loop={true}
        autoplay={{
          delay: 500,
          disableOnInteraction: false,
        }}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <div className="swiper-container">
    {imagePaths.map((image, index) => (
      <SwiperSlide key={index}>
        <img src={image} alt="chief" />
      </SwiperSlide>
    ))}
  </div>
      </Swiper>
    </>
  );
}