import { useEffect } from "react";
import {analytics } from "../firebase-config";
import { logEvent } from "firebase/analytics";
import HomeHead from '../Pages/HomePage/HomeHead';
import { motion } from 'framer-motion'
import HomeScience from '../Pages/HomePage/HomeScience';
import Footer from '../components/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import BlogMain from '../Pages/HomePage/BlogMain';
import HomeChief from "../Pages/HomePage/HomeChief";
import LogoutButton from '../components/LoginAuth/LogoutButton';


const Home = () => {
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: window.location.pathname,
      page_title: document.title,
    });
  }, []);
  return (
    <>
      <LogoutButton />
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: "100%", transition: { duration: 0.1 } }}
        transition={{ ease: "easeOut", duration: 0.5 }}
      >
        <HomeHead />
        <HomeChief />
        <HomeScience />
        <BlogMain />
        <Footer />
        <ScrollToTop />
      </motion.div>
    </>
  );
};

export default Home;