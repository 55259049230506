import React, { useState, useEffect, lazy, Suspense } from 'react';
import './Footer.css';
import FooterOverlay from './FooterOverlay';
import { FiFacebook, FiYoutube, FiInstagram } from 'react-icons/fi';
import images from '../../constants/images';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { SlSocialSpotify } from 'react-icons/sl';
import VIPModal from './VIPModal';

const LazyNewsletter = lazy(() => import('./Newsletter'));

const Footer = () => {
  const [clickCount, setClickCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogoClick = () => {
    setClickCount(clickCount + 1);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setClickCount(0);
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    if (clickCount === 3) {
      setIsModalOpen(true);
      document.body.style.overflow = 'hidden';
    }
  }, [clickCount]);

  return (
    <div className="app__footer section__padding" id="login">
      <FooterOverlay />
      <Suspense fallback={<h2>Loading Newsletter...</h2>}>
        <LazyNewsletter />
      </Suspense>
      <div className="app__footer-links">
        <div className="app__footer-links_contact">
          <h1 className="app__footer-headtext">Keep the Knowledge <br/>no matter what you do</h1>
          <p className="p__opensans">P.O. Box 608, Mableton, GA 30126</p>
        </div>
        <div className="app__footer-links_logo">
          <img src={images.imaginenationlogo} alt="footer_logo" onClick={handleLogoClick} />
          <p className="p__opensans">&quot;The best way to find yourcellf is to lose yourcellf in the service of others.&quot;</p>
          <img src={images.spoon} alt="feather" className="spoon__img" style={{ marginTop: 15 }} />
          <div className="app__footer-links_icons">
            <a href="https://m.facebook.com/eligio.bishop.92/" target="_blank" rel="noreferrer" ><FiFacebook /></a>
            <a href="https://www.youtube.com/@NatureboyTv" target="_blank" rel="noreferrer" ><FiYoutube /></a>
            <a href="https://instagram.com/natureboytv?igshid=OGQ2MjdiOTE" target="_blank" rel="noreferrer" ><FiInstagram /></a>
            <a href='https://open.spotify.com/artist/1D0MeqdWq34W1oIMBHS8dr?si=vKay0JDyTgaKTFYw-NhqAQ' target='_blank' rel='noreferrer'>
              <SlSocialSpotify className='spotify-icon'/> </a>
            <a href='https://cash.app/$3ligio13ishop' target='_blank' rel='noreferrer'>
              <MdOutlineAttachMoney className='cash-app'/></a>
          </div>
        </div>
        <div className="app__footer-links_work">
          <h1 className="app__footer-headtext">The kingdom of heaven is <br/>With I.N</h1>
          <p className="p__opensans">I love mycellf</p>
        </div>
      </div>
      <div className="footer__copyright">
        <p className="p__opensans">2015 NatureboyTV. All Rights reserved.</p>
      </div>
      {isModalOpen && <VIPModal onClose={closeModal} />}
    </div>
  );
};

export default Footer;
