import {motion} from 'framer-motion';
import './BlogHead.css'



const BlogPost = () => {
  return (
    <motion.div className='blog-hero'>
      <div className='blog-content'>
        <h3>ImagineNation | "The Only Way Out is I.N."</h3>
          <motion.h1
          initial={{opacity:0}}
          whileInView={{opacity:1}}
          transition={{delay:0.5, duration:1.2}}> Our Daily Bread</motion.h1>
      </div>
    </motion.div>
  )
}

export default BlogPost
