import { Outlet } from 'react-router-dom';
import Login from '../components/LoginAuth/Login';
import { UserAuth } from "../context/UserAuthContext";


const useAuth = () => {
  const { currentUser } = UserAuth();
  return currentUser != null;
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Login />;
};

export default ProtectedRoutes;


