import React, { useRef, useState } from 'react';
import './HomeHead.css';
import { motion } from 'framer-motion';
import { BsFillPlayFill, BsPauseFill } from 'react-icons/bs';
import heaven from '../../assets/heaven.mp3';

const HomeHead = () => {
  const [playAudio, setPlayAudio] = useState(false);
  const audioRef = useRef();

  return (
    <div>
      <div className='hero-image'>
        <div className='hero-content'>
          <div className='hero-content-inner'>
            <h3>the only way out is I.N. imagineNation</h3>
            <motion.h1
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 1.2 }}
            >
              the kingdom of heaven is with I.N.
            </motion.h1>
            <p>
              "To know nothing is to know everything and to know everything is
              to be everything. Peace be still, and know that you are God." <br />
              ~ 3 God
            </p>
            <audio
              ref={audioRef}
              src={heaven}
              type='audio/mp3'
              loop
              controls={false}
            />
            <button
              type='button'
              className='content__buttonL'
              onClick={() => {
                setPlayAudio(!playAudio);
                if (playAudio) {
                  audioRef.current.pause();
                } else {
                  audioRef.current.play();
                }
              }}
              aria-label='Play/Pause Audio'
            >
              {playAudio ? (
                <BsPauseFill color='#fff' fontSize={30} />
              ) : (
                <BsFillPlayFill color='#fff' fontSize={30} />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHead;
