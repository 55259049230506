import './BooksSix.css';
import images from '../../constants/images';
import {motion} from 'framer-motion';


const BooksSix = () => {

  return (
    <motion.div className='book-cover'>
      <div className='app__bgO '>
      <div className='body-book'>
        <div className='row-book'>
          <div className='left-book'>
          <img src={images.book6} alt='cookbook'/>
          </div>
          <div className='right-book'>
            <div className='content-book'>
              <h3 className='book-title'>the guide to understanding cellf</h3>
              <img src={images.spoon} alt='feather' className='feather-img-books'/>
                <motion.h1 
                  initial={{opacity:0}}
                  whileInView={{opacity:3}}
                  transition={{delay:1, duration:1.2}}
                  className='book-subtitle'>Divine Knowledge of Cellf Study Guide
                </motion.h1>
                  <p className='p__opensans'>This study guide provides comprehensive knowledge for those seeking to understand the sciences of ImagineNation and an introduction into the profound and brilliant teachings of The Most High Chief Eligio The Christ.
                  </p>
                  <a href='https://books.apple.com/us/book/divine-knowledge-of-cellf-study-guide/id6443235625' target='blank'rel='noreferrer'> <button type="button" className='content__button'>Learn More</button>
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </motion.div>
    )
  }

export default BooksSix;