import './Faith.css';
import images from '../../../constants/images';
import {motion} from 'framer-motion';


const Faith = () => {
  return (
    <div className='faith-container-full'>
    <div className='faith-container-main'>
      <div className='faith-container-row'>
        <div className='faith-card-two'>
          <motion.img
            src={images.faithimage1} alt='faith' className='faith-img'/>
        </div>
        <div className='faith-card-two'>
          <h3 className='faith-title'>The Mind of ImagineNation</h3>
          <img src={images.spoon} alt='feather' className='feather-img'/>
          <motion.h1 
            initial={{opacity:0}}
            whileInView={{opacity:3}}
            transition={{delay:1, duration:1.2}}
            className='faith-subtitle'>Goddoes Faith Eniyan
            </motion.h1>
              <p className='p__opensansA'>Goddoes Faith Eniyan is a shining example of a divine feminine wombman who embodies spiritual devotion and a deep love for knowledge. Her passion for the 5 sciences is palpable, and she has devoted her life to teaching others about them. Whether she is leading a workshop or giving a talk, Goddoes Faith Eniyan brings a contagious energy and a wealth of knowledge to every interaction. She understands that there is more to life than what we can see with our physical eyes, and she is committed to helping others connect with the deeper, more meaningful aspects of their existence.
              </p>
              <br/>
              <p className="p__opensansA">In addition to her work as a teacher, Goddoes Faith Eniyan is a multifaceted wombman who loves to sing, dance, create art, and spend time in nature. Her love of creativity and the natural world is a reflection of her deep connection to the divine, and she sees these activities as a way to express the beauty and wonder of the youniverse. She is also deeply grateful for the guidance and wisdom of her teacher/guru, and she honors their teachings by sharing them with others in her own unique way. In all of her endeavors, Goddoes Faith Eniyan embodies the divine feminine qualities of wisdom, and creativity. She is a true inspiration to all who know her.
              </p>
              <div className='faith-container-row'>
        <div className='faith-card-one'>
          <img src={images.faithimage4}  alt="faith" className='faith-img-card' />
          <div>
          </div>
        </div>
        <div className='faith-card-one'>
          <img src={images.faithimage2}  alt="faith" className='faith-img-card' />
          <div>
          </div>
        </div>
        <div className='faith-card-one'>
          <img src={images.faithimage3}  alt="faith" className='faith-img-card' />
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default Faith



