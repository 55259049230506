import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SignUp.css';
import { UserAuth } from '../../context/UserAuthContext';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { createUser } = UserAuth();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);
    try {
      await createUser(email, password);
      setEmail('');
      setPassword('');
      setIsSuccess(true);
      setTimeout(() => {
        navigate('/home');
      }, 3000);
    } catch (error) {
      setError(error.message);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <div className="signup-auth-container">
        {isSuccess && <p className="account-created">Account successfully created!</p>}
        {error && <p className="error-login">{error}</p>}
        <form onSubmit={handleSubmit} className="form">
          <h2 className="auth-h2">Create an account</h2>
          <div>
            <label className="login-label" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              id="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input"
            />
          </div>
          <div className="password-input-container">
            <label className="login-label" htmlFor="password">
              Password:
            </label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input"
            />
            <button
              type="button"
              className="password-toggle-btn"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FiEyeOff /> : <FiEye />}
            </button>
          </div>
          <button type="submit" className="login-submit-btn" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Sign Up'}
          </button>
          <div className="signup-auth">
            <p className="login-auth-para">
              Already have an account? <Link to="/">Login</Link>
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignUp;
