import { useEffect } from "react";
import {analytics } from "../firebase-config";
import { logEvent } from "firebase/analytics";
import React, { lazy, Suspense } from 'react';
import { motion } from 'framer-motion';
import Sidebar from '../Pages/LibraryPage/Sidebar';

const LazyLatestUploads = lazy(() => import('../Pages/LibraryPage/LatestUploads'));

const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
const CHANNEL_ID = 'UCyK4e88TWxbgOcBW_F08AIg';

const Library = () => {
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: window.location.pathname,
      page_title: document.title,
    });
  }, []);
  return (
    <>
      <div style={{ position: 'fixed', top: 70, left: 0, right: 0, backgroundColor: '#000' }}>
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: '100%' }}
          exit={{ x: '100%', transition: { duration: 0.1 } }}
        >
          <Suspense fallback={<div>Loading latest uploads...</div>}>
            <LazyLatestUploads API_KEY={API_KEY} CHANNEL_ID={CHANNEL_ID} />
          </Suspense>
        </motion.div>
      </div>
      <Sidebar />
    </>
  );
};

export default Library;
