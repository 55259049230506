import { useEffect } from "react";
import {analytics } from "../firebase-config";
import { logEvent } from "firebase/analytics";
import { motion } from 'framer-motion';
import AboutPage from '../Pages/AboutPage/AboutPage';
import Chief from '../Pages/AboutPage/Chief';
import Footer from '../components/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import ChiefVideos from '../Pages/AboutPage/ChiefVideos';
import ChiefImages from '../Pages/AboutPage/ChiefImages';
import MainVideo from '../components/MainVideo/MainVideo';
import LogoutButton from '../components/LoginAuth/LogoutButton';



const About = () => {
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: window.location.pathname,
      page_title: document.title,
    });
  }, []);
  return (
    <motion.div initial=
      {{width: 0}} 
      animate={{width: "100%"}} 
      exit={{x: "100%", transition: {duration: 0.1}}}>
    <LogoutButton />
    <AboutPage/>
    <Chief/>
    <ChiefVideos />
    <ChiefImages/>
    <MainVideo />
    <Footer/>
    <ScrollToTop/>
  </motion.div>   
  )
}

export default About
