import './Efuru.css';
import images from '../../../constants/images';
import {motion} from 'framer-motion';



const Efuru = () => {
  return (
    <div className='efuru-container-full'>
      <div className='efuru-container-main'>
        <div className='efuru-container-row'>
          <div className='efuru-card-two'>
            <motion.img
              src={images.efuruimage1} alt='efuru' className='efuru-img'/>
          </div>
          <div className='efuru-card-two'>
            <h3 className='efuru-title'>The spirit of ImagineNation</h3>
            <img src={images.spoon} alt='feather' className='feather-img'/>
            <motion.h1 
              initial={{opacity:0}}
              whileInView={{opacity:3}}
              transition={{delay:1, duration:1.2}}
              className='efuru-subtitle'>Goddoes Efuru Eniyan
              </motion.h1>
                <p className='p__opensansA'>Goddoes Efuru Eniyan is a true renaissance wombman, embodying a divine spirit that permeates every aspect of her being. Her passion for esoteric knowledge is matched only by her ability to distill complex ideas into the simplest form, making them accessible to all who seek enlightenment. Whether singing, painting, or dancing, she infuses her art with a deep sense of beauty and spirituality that touches the sol. Her connection to nature is palpable, and it is clear that she sees the world through a lens of wonder and reverence.
                </p>
                <br/>
                <p className="p__opensansA">In every endeavor, Goddoes Efuru Eniyan brings a sense of grace and harmony that is truly awe-inspiring. Her art is a reflection of her innermost being, an expression of the deep love and joy that she feels for the world around her. As a teacher of esoteric knowledge, she is a guiding light, leading her students on a journey of cellf-discovery and transformation. Her voice is like honey, soothing and uplifting, and her presence is a balm for the weary sol. Goddoes Efuru Eniyan is a beacon of hope and inspiration, a shining example of what it means to live a life of purpose and passion.
                </p>
                <br/>
                <p className="p__opensansA">In her heart, Goddoes Efuru Eniyan knows that she is connected to something much larger than herself. She recognizes that her gifts are not her own, but are instead a manifestation of the divine spirit that flows through her. Her art is a testament to this connection, a celebration of the beauty and mystery of life. Through her teachings, she shares this sense of wonder and awe with others, inspiring them to seek their own inner truth and to live a life of purpose and meaning. Goddoes Efuru Eniyan is a rare gem, a true treasure of the hueman spirit, and her light will continue to shine brightly for generations to come.
                </p>
                <div className='efuru-container-row'>
          <div className='efuru-card-one'>
            <img src={images.efuruimage4}  alt="efuru" className='efuru-img-card' />
            <div>
            </div>
          </div>
          <div className='efuru-card-one'>
            <img src={images.efuruimage2}  alt="efuru" className='efuru-img-card' />
            <div>
            </div>
          </div>
          <div className='efuru-card-one'>
            <img src={images.efuruimage3}  alt="efuru" className='efuru-img-card' />
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Efuru
